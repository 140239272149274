//==============================
// Header
//==============================

.header {

  &-logo {
    text-decoration: none;
    width: 120px;
    height: 60px;
    background-color: $charcoal;
    position: fixed;
    left: 0;
    top: 20px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .3s;

    @include bp(s) {
      width: 80px;
      height: 50px;
      top: 10px;
    }

    svg {
      display: block;
      fill: $brown;
      width: 70%;
      height: auto;
      // position: absolute;
      z-index: 11;
    }

    &:hover,
    &:focus {
      background: #050505;
    }
  }

  &-button {
    @include input-reset;
    background: $brown;
    position: fixed;
    width: 60px;
    height: 60px;
    right: 0;
    top: 20px;
    outline: 0;
    z-index: 10;
    transition: background-color .3s;

    @include bp(s) {
      width: 50px;
      height: 50px;
      top: 10px;
    }

    &:hover,
    &:focus {
      cursor: pointer;
      background: darken($brown, 10%);
    }

    &-item {
      display: block;
      width: 28px;
      height: 2px;
      background-color: $white;
      position: absolute;
      left: 50%;
      top: 50%;
      opacity: 1;
      transition: transform .3s, opacity .3s, width .3s, background-color .3s;

      &.top {
        transform: translate(-50%, -7px) rotate(0deg);
        transform-origin: left top;
      }

      &.middle {
        transform: translate(-50%, 0) rotate(0deg);
      }

      &.bottom {
        transform-origin: left bottom;
        transform: translate(-50%, 7px) rotate(0deg);
      }
    }

    &.open {

      .header-button-item {

        &.top {
          transform: translate(-35%, -10px) rotate(45deg);
        }

        &.middle {
          opacity: 0;
        }

        &.bottom {
          transform: translate(-35%, 9px) rotate(-45deg);
        }
      }
    }
  }

  &-nav {
    position: fixed;
    width: 0;
    height: 0;
    right: 0;
    top: 0;
    background: #050505;
    z-index: 3;
    overflow: hidden;
    transition: width .4s .3s, height .4s .3s;

    &.open {
      width: 100%;
      height: 100%;
      transition-delay: 0s;
      transition-duration: .5s;

      .header-nav-item {
        opacity: 1;
        transition-duration: .5s;

        &:nth-child(1) {
          transition-delay: .5s;
        }

        &:nth-child(2) {
          transition-delay: .6s;
        }

        &:nth-child(3) {
          transition-delay: .7s;
        }

        &:nth-child(4) {
          transition-delay: .8s;
        }
      }
    }

    &-items {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &-item {
      list-style-type: none;
      font-weight: bold;
      font: 400 normal 60px/1 $bebas;
      letter-spacing: .05em;
      text-transform: uppercase;
      text-align: center;

      opacity: 0;
      transition: opacity .3s 0s;

      @include bp(l) {
        font-size: 50px;
      }

      @include bp(m) {
        font-size: 45px;
      }

      @include bp(s) {
        font-size: 40px;
      }

      @include bp(xs) {
        font-size: 30px;
      }

      &.link {
        width: calc(100% / 3);
        height: 70%;
        border-right: #333 solid 1px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @include bp(s) {
          width: 100%;
          height: 25%;
          border-right: 0;
          border-bottom: #333 solid 1px;
        }

        &:nth-child(3) {
          border-right: 0;
        }
      }

      &.contact {
        width: 100%;
        height: 30%;
        border-top: #333 solid 1px;

        @include bp(s) {
          width: 100%;
          height: 25%;
          border-top: 0;
        }
      }
    }

    a {
      width: 100%;
      height: 100%;
      text-decoration: none;
      transition: background-color .2s, color .2s;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #161616;

      &:hover,
      &:focus {
        background-color: #333;
      }
    }
  }
}
