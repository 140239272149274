//==============================
// Roles
//==============================

.roles {
  margin-top: 15px;

  @include bp(s) {
    margin-top: 10px;
  }

  li {
    font: 400 normal 16px/1.6 $rubik;
    color: $grey;
    list-style-type: none;

    &:before {
      content: '+ ';
      opacity: .7;
    }
  }
}
