//==============================
// Web
//==============================

.web {
  margin: 0 auto 100px;
  width: calc(100% - 120px);
  max-width: 1600px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @include bp(s) {
    width: calc(100% - 40px);
    margin-bottom: 60px;
  }

  &-img {
    margin-bottom: 40px;
    width: 70%;
    border-radius: 10px 10px 5px 5px;
    position: relative;
    overflow: hidden;

    @include bp(l) {
      width: 60%;
      margin-bottom: 30px;
    }

    @include bp(s) {
      width: 100%;
      margin-bottom: 20px;
      border-radius: 5px;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 30px;
      background: #ddd url('assets/images/web/browser-dots.svg') 15px center / auto 35% no-repeat;
      z-index: 2;

      @include bp(s) {
        height: 20px;
        background-position: 10px center;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &-content {
    width: calc(30% - 60px);

    @include bp(l) {
      width: calc(40% - 60px);
    }

    @include bp(s) {
      width: 100%;
    }
  }

  .text {
    margin-top: 20px;
  }

  &-link {
    margin-top: 40px;

    @include bp(s) {
      margin-top: 20px;
    }

    .button {
      display: block;
      text-align: center;

      @include bp(s) {
        display: inline-block;
      }
    }
  }
}
