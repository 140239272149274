//==============================
// Footer
//==============================

.footer {
  background-color: #111;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  &-social {
    
    &-items {
      display: flex;
      flex-flow: row nowrap;
    }

    &-item {
      list-style-type: none;
    }

    a {
      display: block;
      text-indent: -9999px;
      overflow: hidden;
      width: 50px;
      height: 50px;
      background-position: center center;
      background-size: auto 50%;
      background-repeat: no-repeat;
      opacity: .7;
      transition: opacity .2s;

      &:hover {
        opacity: 1;
      }

      &.instagram {
        background-image: url('assets/images/global/instagram.svg');
      }

      &.linkedin {
        background-image: url('assets/images/global/linkedin.svg');
      }

      &.soundcloud {
        background-image: url('assets/images/global/soundcloud.svg');
      }
    }
  }
}
