//==============================
// Contact Form 7
//==============================

// .wpcf7-not-valid {
//   box-shadow: inset 0 0 0px 2px $brown !important;
// }

.wpcf7-not-valid-tip {
  color: red;
  display: block;
  padding: 7px 0 5px;
  text-align: left;
  font: 400 normal 14px/1.5 $rubik !important;
  letter-spacing: .02em;
}

.wpcf7-response-output {
  color: $brown;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  width: 100%;
  clear: both;
  left: 0;
  font: 300 normal 18px/1.5 $rubik !important;

  @include bp(m) {
    font-size: 17px !important;
  }

  @include bp(s) {
    font-size: 16px !important;
  }

  @include bp(xs) {
    font-size: 15px !important;
  }
}

@keyframes loaderSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

span.ajax-loader {
  left: calc(50% - 8px);
  position: relative;
  margin: 10px 0 0 !important;
  display: block !important;
  background: none !important;
  border-radius: 50%;
  border-left: 2px solid $brown !important;
  border-top: 2px solid $brown !important;
  border-bottom: 2px solid transparent !important;
  border-right: 2px solid transparent !important;
  animation: loaderSpin .7s infinite linear;
}
