//==============================
// Home
//==============================

.home {

  &-hero {
    @include bg-cover;
    background-image: url('assets/images/home/robert-pessagno-drums.jpg');
    height: calc(100vh - 100px);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;

    @include bp(s) {
      background-image: url('assets/images/home/robert-pessagno-drums-mobile.jpg');
    }

    &:after {
      content: '';
      @include fillcontainer;
      background: transparentize($black, .25) url('assets/images/global/noise-texture.png') center center / 50px auto repeat;
      z-index: 1;
    }
  }
  
  &-title {
    width: 100%;
    font: 400 normal 100px/.8 $bebas;
    letter-spacing: .03em;
    text-transform: uppercase;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    position: relative;
    z-index: 2;
    color: $white;

    @include bp(l) {
      font-size: 80px;
    }

    @include bp(m) {
      font-size: 65px;
    }

    @include bp(s) {
      font-size: 50px;
    }

    @include bp(xs) {
      font-size: 35px;
    }
  }

  &-text {
    position: relative;
    width: 80%;
    max-width: 800px;
    z-index: 2;
    margin-top: 1em;
    font: 400 normal 28px/1.4 $rubik;
    color: $brown;
    text-shadow: 0 0 15px #000;

    @include bp(m) {
      font-size: 24px;
      max-width: 600px;
    }

    @include bp(s) {
      font-size: 18px;
    }
  }

  &-nav {
    width: 100%;
    font: 400 normal 40px/.8 $bebas;
    letter-spacing: .03em;
    text-transform: uppercase;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    position: relative;
    z-index: 2;
    color: $white;
    margin-top: 30px;

    @include bp(s) {
      font-size: 34px;
    }

    @include bp(xs) {
      font-size: 30px;
    }

    li {
      list-style-type: none;
      margin: 0 .5em;
      position: relative;

      &:after {
        content: '//';
        position: absolute;
        right: -.8em;
        top: .1em;
        color: $grey;
      }

      &:last-child {

        &:after {
          display: none;
        }
      }
    }

    a {
      color: inherit;
      text-decoration: none;
      position: relative;
      display: inline-block;
      padding: .1em .1em .05em;
      transition: color .3s;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        left: auto;
        top: 0;
        width: 0;
        height: 105%;
        transition: width .3s;
        background-color: $brown;
        z-index: -1;

        @include bp(l) {
          display: none;
        }
      }

      &:hover {
        color: $black;

        @include bp(l) {
          color: inherit;
        }

        &:before {
          width: 100%;
          left: 0;
          right: auto;
        }
      }
    }
  }
}
