//==============================
// Design
//==============================

.design {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 30px 80px;

  @include bp(s) {
    padding: 0 20px 100px;
  }

  &-item {
    width: calc((100% / 3) - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    padding-top: calc((100% / 3) - 20px);
    position: relative;

    @include bp(m) {
      padding-top: calc(50% - 10px);
      width: calc(50% - 10px);
      margin-bottom: 20px;
    }

    @include bp(s) {
      width: 100%;
      padding-top: 100%;
    }

    img {
      margin: 0 auto;
      width: 80%;
      height: 65%;
      object-fit: contain;
      @include centered;
    }
  }
}