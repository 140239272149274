//==============================
// Buttons
//==============================

.button {
  font: 400 normal 22px/1.3 $bebas;
  text-transform: uppercase;
  color: $white;
  letter-spacing: .05em;
  background-color: $brown;
  display: inline-block;
  padding: .5em 1.2em;
  text-decoration: none;
  transition: background-color .3s;
  border: 0;
  border-radius: 2px;

  @include bp(m) {
    font-size: 20;
  }

  @include bp(s) {
    font-size: 18px;
  }

  &:hover,
  &:focus {
    outline: 0;
    cursor: pointer;
    background-color: darken($brown, 10%);
  }
}
