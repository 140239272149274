/*!
Theme Name: Robert Pessagno Portfolio
Author: Robert Pessagno
Version: 1.0
*/

//==============================
// Main
//==============================


// Global
//------------------------------

@import 'global/mixins';
@import 'global/fonts';
@import 'global/variables';
@import 'global/normalize';
@import 'global/global';


// Components
//------------------------------

@import 'components/typography';
@import 'components/forms';
@import 'components/buttons';
@import 'components/header';
@import 'components/footer';
@import 'components/main';
@import 'components/intro';
@import 'components/roles';


// Libraries
//------------------------------

@import 'lib/slick';
@import 'lib/contact-form-7';


// Templates
//------------------------------

@import 'templates/home';
@import 'templates/music';
@import 'templates/design';
@import 'templates/web';
@import 'templates/post';
@import 'templates/not-found';

// Print
//------------------------------
@import 'global/print';
