//==============================
// Intro
//==============================

.intro {
  padding: 28px 80px 100px;
  text-align: right;

  @include bp(s) {
    padding: 22px 60px 60px;
  }

  @include bp(xs) {
    // padding: 100px 30px 70px;
  }

  &-title {
    @include title1;

    &:after {
      content: ' //';
      font-weight: 300;
    }
  }
}
