//==============================
// Music
//==============================

.music {
  margin: 0 auto 100px;
  width: calc(100% - 80px);
  max-width: 1000px;

  @include bp(s) {
    width: calc(100% - 40px);
    margin-bottom: 60px;
  }

  &-player {
    margin-bottom: 20px;
  }
}
