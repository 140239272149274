/*!
Theme Name: Robert Pessagno Portfolio
Author: Robert Pessagno
Version: 1.0
*/
@font-face {
  font-family: "Bebas Neue";
  src: url("assets/fonts/bebas-neue-regular.woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Bebas Neue";
  src: url("assets/fonts/bebas-neue-bold.woff");
  font-weight: 700;
  font-style: normal;
}
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

* {
  box-sizing: border-box;
}

body,
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

a {
  outline: none;
}
a:link, a:visited, a:focus, a:hover, a:active {
  outline: none;
}

h1, h2, h3, h4, h5, h6, p, ul, ol, li {
  margin: 0;
  padding: 0;
}

.title1 {
  font: 400 normal 48px/1 "Bebas Neue", Arial Narrow, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #888;
}
@media screen and (max-width: 767px) {
  .title1 {
    font-size: 30px;
  }
}
.title1 + .text {
  margin-top: 1em;
}

.title2 {
  font: 400 normal 40px/1.2 "Rubik", Helvetica, Arial, sans-serif;
  letter-spacing: -0.01em;
  color: #9e927e;
}
@media screen and (max-width: 1023px) {
  .title2 {
    font-size: 33px;
  }
}
@media screen and (max-width: 767px) {
  .title2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 480px) {
  .title2 {
    font-size: 25px;
  }
}
.title2 + .text {
  margin-top: 0.5em;
}

.text {
  font: 300 normal 18px/1.6 "Rubik", Helvetica, Arial, sans-serif;
  color: #888;
}
@media screen and (max-width: 1023px) {
  .text {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .text {
    font-size: 15px;
  }
}
.text + .button {
  margin-top: 1em;
}
.text a {
  color: #9e927e;
  text-decoration: none;
  border-bottom: #9e927e solid 1px;
  transition: color 0.2s, border-bottom-color 0.2s;
}
.text a:hover, .text a:focus {
  color: #857964;
  border-bottom-color: #857964;
}

select::-ms-expand {
  display: none;
}

.contact-form {
  width: calc(100% - 80px);
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 100px;
}

form {
  width: 100% !important;
}
@media screen and (max-width: 480px) {
  form {
    width: calc(100% - 40px);
  }
}
form div {
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  form div {
    margin-bottom: 10px;
  }
}
form div.third {
  width: calc((100% / 3) - 30px / 2);
}
@media screen and (max-width: 767px) {
  form div.third {
    width: 100%;
  }
}
form div.half {
  width: calc(50% - 10px);
}
@media screen and (max-width: 767px) {
  form div.half {
    width: 100%;
  }
}
form div.full {
  width: 100%;
}

label.wpforms-field-label {
  font: 300 normal 18px/1.6 "Rubik", Helvetica, Arial, sans-serif;
  color: #888;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
}
@media screen and (max-width: 1023px) {
  label.wpforms-field-label {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  label.wpforms-field-label {
    font-size: 15px;
  }
}
@media screen and (max-width: 1023px) {
  label.wpforms-field-label {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 767px) {
  label.wpforms-field-label {
    font-size: 14px !important;
  }
}
label.wpforms-error {
  font: 300 normal 18px/1.6 "Rubik", Helvetica, Arial, sans-serif;
  color: #888;
  font-weight: 400 !important;
  color: #ff0000 !important;
}
@media screen and (max-width: 1023px) {
  label.wpforms-error {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  label.wpforms-error {
    font-size: 15px;
  }
}

input[type=text],
input[type=email],
input[type=tel],
select,
textarea {
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  margin: 0 0 10px !important;
  padding: 1.5em 1.2em !important;
  font: 400 normal 16px/1.5 "Rubik", Helvetica, Arial, sans-serif !important;
  color: #fff !important;
  transition: border-color 0.3s;
  border: #444 solid 2px !important;
  background: #444 !important;
  border-radius: 0 !important;
}
@media screen and (max-width: 1200px) {
  input[type=text],
  input[type=email],
  input[type=tel],
  select,
  textarea {
    font-size: 15px;
  }
}
@media screen and (max-width: 1023px) {
  input[type=text],
  input[type=email],
  input[type=tel],
  select,
  textarea {
    font-size: 14px;
  }
}
input[type=text]:focus,
input[type=email]:focus,
input[type=tel]:focus,
select:focus,
textarea:focus {
  outline: 0;
  border-color: #9e927e !important;
}

select {
  background-image: url("assets/images/global/select-arrow.svg");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right center;
  padding-right: 3em;
}

button[type=submit] {
  margin: 20px auto 0 !important;
  display: block !important;
  font: 400 normal 22px/1.3 "Bebas Neue", Arial Narrow, Helvetica, Arial, sans-serif !important;
  text-transform: uppercase !important;
  color: #fff !important;
  letter-spacing: 0.05em !important;
  background-color: #9e927e !important;
  display: inline-block !important;
  padding: 0.5em 1.2em !important;
  text-decoration: none !important;
  transition: background-color 0.3s !important;
  border: 0 !important;
  border-radius: 2px !important;
}
@media screen and (max-width: 1023px) {
  button[type=submit] {
    font-size: 20 !important;
  }
}
@media screen and (max-width: 767px) {
  button[type=submit] {
    font-size: 18px !important;
  }
}
button[type=submit]:hover, button[type=submit]:focus {
  outline: 0 !important;
  cursor: pointer !important;
  background-color: #857964 !important;
}

.wpforms-container {
  margin: 0 !important;
}

.wpforms-confirmation-container-full {
  background: none !important;
  border: 0 !important;
  text-align: center !important;
}
.wpforms-confirmation-container-full p {
  font: 300 normal 18px/1.6 "Rubik", Helvetica, Arial, sans-serif;
  color: #888;
}
@media screen and (max-width: 1023px) {
  .wpforms-confirmation-container-full p {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .wpforms-confirmation-container-full p {
    font-size: 15px;
  }
}

.button {
  font: 400 normal 22px/1.3 "Bebas Neue", Arial Narrow, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.05em;
  background-color: #9e927e;
  display: inline-block;
  padding: 0.5em 1.2em;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 0;
  border-radius: 2px;
}
@media screen and (max-width: 1023px) {
  .button {
    font-size: 20;
  }
}
@media screen and (max-width: 767px) {
  .button {
    font-size: 18px;
  }
}
.button:hover, .button:focus {
  outline: 0;
  cursor: pointer;
  background-color: #857964;
}

.header-logo {
  text-decoration: none;
  width: 120px;
  height: 60px;
  background-color: #222;
  position: fixed;
  left: 0;
  top: 20px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}
@media screen and (max-width: 767px) {
  .header-logo {
    width: 80px;
    height: 50px;
    top: 10px;
  }
}
.header-logo svg {
  display: block;
  fill: #9e927e;
  width: 70%;
  height: auto;
  z-index: 11;
}
.header-logo:hover, .header-logo:focus {
  background: #050505;
}
.header-button {
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  background: #9e927e;
  position: fixed;
  width: 60px;
  height: 60px;
  right: 0;
  top: 20px;
  outline: 0;
  z-index: 10;
  transition: background-color 0.3s;
}
@media screen and (max-width: 767px) {
  .header-button {
    width: 50px;
    height: 50px;
    top: 10px;
  }
}
.header-button:hover, .header-button:focus {
  cursor: pointer;
  background: #857964;
}
.header-button-item {
  display: block;
  width: 28px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 1;
  transition: transform 0.3s, opacity 0.3s, width 0.3s, background-color 0.3s;
}
.header-button-item.top {
  transform: translate(-50%, -7px) rotate(0deg);
  transform-origin: left top;
}
.header-button-item.middle {
  transform: translate(-50%, 0) rotate(0deg);
}
.header-button-item.bottom {
  transform-origin: left bottom;
  transform: translate(-50%, 7px) rotate(0deg);
}
.header-button.open .header-button-item.top {
  transform: translate(-35%, -10px) rotate(45deg);
}
.header-button.open .header-button-item.middle {
  opacity: 0;
}
.header-button.open .header-button-item.bottom {
  transform: translate(-35%, 9px) rotate(-45deg);
}
.header-nav {
  position: fixed;
  width: 0;
  height: 0;
  right: 0;
  top: 0;
  background: #050505;
  z-index: 3;
  overflow: hidden;
  transition: width 0.4s 0.3s, height 0.4s 0.3s;
}
.header-nav.open {
  width: 100%;
  height: 100%;
  transition-delay: 0s;
  transition-duration: 0.5s;
}
.header-nav.open .header-nav-item {
  opacity: 1;
  transition-duration: 0.5s;
}
.header-nav.open .header-nav-item:nth-child(1) {
  transition-delay: 0.5s;
}
.header-nav.open .header-nav-item:nth-child(2) {
  transition-delay: 0.6s;
}
.header-nav.open .header-nav-item:nth-child(3) {
  transition-delay: 0.7s;
}
.header-nav.open .header-nav-item:nth-child(4) {
  transition-delay: 0.8s;
}
.header-nav-items {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.header-nav-item {
  list-style-type: none;
  font-weight: bold;
  font: 400 normal 60px/1 "Bebas Neue", Arial Narrow, Helvetica, Arial, sans-serif;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s 0s;
}
@media screen and (max-width: 1200px) {
  .header-nav-item {
    font-size: 50px;
  }
}
@media screen and (max-width: 1023px) {
  .header-nav-item {
    font-size: 45px;
  }
}
@media screen and (max-width: 767px) {
  .header-nav-item {
    font-size: 40px;
  }
}
@media screen and (max-width: 480px) {
  .header-nav-item {
    font-size: 30px;
  }
}
.header-nav-item.link {
  width: 33.3333333333%;
  height: 70%;
  border-right: #333 solid 1px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .header-nav-item.link {
    width: 100%;
    height: 25%;
    border-right: 0;
    border-bottom: #333 solid 1px;
  }
}
.header-nav-item.link:nth-child(3) {
  border-right: 0;
}
.header-nav-item.contact {
  width: 100%;
  height: 30%;
  border-top: #333 solid 1px;
}
@media screen and (max-width: 767px) {
  .header-nav-item.contact {
    width: 100%;
    height: 25%;
    border-top: 0;
  }
}
.header-nav a {
  width: 100%;
  height: 100%;
  text-decoration: none;
  transition: background-color 0.2s, color 0.2s;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #161616;
}
.header-nav a:hover, .header-nav a:focus {
  background-color: #333;
}

.footer {
  background-color: #111;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-social-items {
  display: flex;
  flex-flow: row nowrap;
}
.footer-social-item {
  list-style-type: none;
}
.footer-social a {
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  background-position: center center;
  background-size: auto 50%;
  background-repeat: no-repeat;
  opacity: 0.7;
  transition: opacity 0.2s;
}
.footer-social a:hover {
  opacity: 1;
}
.footer-social a.instagram {
  background-image: url("assets/images/global/instagram.svg");
}
.footer-social a.linkedin {
  background-image: url("assets/images/global/linkedin.svg");
}
.footer-social a.soundcloud {
  background-image: url("assets/images/global/soundcloud.svg");
}

.main {
  background: #222;
  min-height: calc(100vh - 100px);
}

.intro {
  padding: 28px 80px 100px;
  text-align: right;
}
@media screen and (max-width: 767px) {
  .intro {
    padding: 22px 60px 60px;
  }
}
.intro-title {
  font: 400 normal 48px/1 "Bebas Neue", Arial Narrow, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #888;
}
@media screen and (max-width: 767px) {
  .intro-title {
    font-size: 30px;
  }
}
.intro-title:after {
  content: " //";
  font-weight: 300;
}

.roles {
  margin-top: 15px;
}
@media screen and (max-width: 767px) {
  .roles {
    margin-top: 10px;
  }
}
.roles li {
  font: 400 normal 16px/1.6 "Rubik", Helvetica, Arial, sans-serif;
  color: #888;
  list-style-type: none;
}
.roles li:before {
  content: "+ ";
  opacity: 0.7;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.wpcf7-not-valid-tip {
  color: red;
  display: block;
  padding: 7px 0 5px;
  text-align: left;
  font: 400 normal 14px/1.5 "Rubik", Helvetica, Arial, sans-serif !important;
  letter-spacing: 0.02em;
}

.wpcf7-response-output {
  color: #9e927e;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  width: 100%;
  clear: both;
  left: 0;
  font: 300 normal 18px/1.5 "Rubik", Helvetica, Arial, sans-serif !important;
}
@media screen and (max-width: 1023px) {
  .wpcf7-response-output {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 767px) {
  .wpcf7-response-output {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 480px) {
  .wpcf7-response-output {
    font-size: 15px !important;
  }
}

@keyframes loaderSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
span.ajax-loader {
  left: calc(50% - 8px);
  position: relative;
  margin: 10px 0 0 !important;
  display: block !important;
  background: none !important;
  border-radius: 50%;
  border-left: 2px solid #9e927e !important;
  border-top: 2px solid #9e927e !important;
  border-bottom: 2px solid transparent !important;
  border-right: 2px solid transparent !important;
  animation: loaderSpin 0.7s infinite linear;
}

.home-hero {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("assets/images/home/robert-pessagno-drums.jpg");
  height: calc(100vh - 100px);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 767px) {
  .home-hero {
    background-image: url("assets/images/home/robert-pessagno-drums-mobile.jpg");
  }
}
.home-hero:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.75) url("assets/images/global/noise-texture.png") center center/50px auto repeat;
  z-index: 1;
}
.home-title {
  width: 100%;
  font: 400 normal 100px/0.8 "Bebas Neue", Arial Narrow, Helvetica, Arial, sans-serif;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;
  z-index: 2;
  color: #fff;
}
@media screen and (max-width: 1200px) {
  .home-title {
    font-size: 80px;
  }
}
@media screen and (max-width: 1023px) {
  .home-title {
    font-size: 65px;
  }
}
@media screen and (max-width: 767px) {
  .home-title {
    font-size: 50px;
  }
}
@media screen and (max-width: 480px) {
  .home-title {
    font-size: 35px;
  }
}
.home-text {
  position: relative;
  width: 80%;
  max-width: 800px;
  z-index: 2;
  margin-top: 1em;
  font: 400 normal 28px/1.4 "Rubik", Helvetica, Arial, sans-serif;
  color: #9e927e;
  text-shadow: 0 0 15px #000;
}
@media screen and (max-width: 1023px) {
  .home-text {
    font-size: 24px;
    max-width: 600px;
  }
}
@media screen and (max-width: 767px) {
  .home-text {
    font-size: 18px;
  }
}
.home-nav {
  width: 100%;
  font: 400 normal 40px/0.8 "Bebas Neue", Arial Narrow, Helvetica, Arial, sans-serif;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;
  z-index: 2;
  color: #fff;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .home-nav {
    font-size: 34px;
  }
}
@media screen and (max-width: 480px) {
  .home-nav {
    font-size: 30px;
  }
}
.home-nav li {
  list-style-type: none;
  margin: 0 0.5em;
  position: relative;
}
.home-nav li:after {
  content: "//";
  position: absolute;
  right: -0.8em;
  top: 0.1em;
  color: #888;
}
.home-nav li:last-child:after {
  display: none;
}
.home-nav a {
  color: inherit;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding: 0.1em 0.1em 0.05em;
  transition: color 0.3s;
}
.home-nav a:before {
  content: "";
  position: absolute;
  right: 0;
  left: auto;
  top: 0;
  width: 0;
  height: 105%;
  transition: width 0.3s;
  background-color: #9e927e;
  z-index: -1;
}
@media screen and (max-width: 1200px) {
  .home-nav a:before {
    display: none;
  }
}
.home-nav a:hover {
  color: #000;
}
@media screen and (max-width: 1200px) {
  .home-nav a:hover {
    color: inherit;
  }
}
.home-nav a:hover:before {
  width: 100%;
  left: 0;
  right: auto;
}

.music {
  margin: 0 auto 100px;
  width: calc(100% - 80px);
  max-width: 1000px;
}
@media screen and (max-width: 767px) {
  .music {
    width: calc(100% - 40px);
    margin-bottom: 60px;
  }
}
.music-player {
  margin-bottom: 20px;
}

.design {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 30px 80px;
}
@media screen and (max-width: 767px) {
  .design {
    padding: 0 20px 100px;
  }
}
.design-item {
  width: calc(33.3333333333% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding-top: calc(33.3333333333% - 20px);
  position: relative;
}
@media screen and (max-width: 1023px) {
  .design-item {
    padding-top: calc(50% - 10px);
    width: calc(50% - 10px);
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .design-item {
    width: 100%;
    padding-top: 100%;
  }
}
.design-item img {
  margin: 0 auto;
  width: 80%;
  height: 65%;
  object-fit: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.web {
  margin: 0 auto 100px;
  width: calc(100% - 120px);
  max-width: 1600px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .web {
    width: calc(100% - 40px);
    margin-bottom: 60px;
  }
}
.web-img {
  margin-bottom: 40px;
  width: 70%;
  border-radius: 10px 10px 5px 5px;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 1200px) {
  .web-img {
    width: 60%;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .web-img {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
  }
}
.web-img:before {
  content: "";
  display: block;
  width: 100%;
  height: 30px;
  background: #ddd url("assets/images/web/browser-dots.svg") 15px center/auto 35% no-repeat;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .web-img:before {
    height: 20px;
    background-position: 10px center;
  }
}
.web-img img {
  display: block;
  width: 100%;
  height: auto;
}
.web-content {
  width: calc(30% - 60px);
}
@media screen and (max-width: 1200px) {
  .web-content {
    width: calc(40% - 60px);
  }
}
@media screen and (max-width: 767px) {
  .web-content {
    width: 100%;
  }
}
.web .text {
  margin-top: 20px;
}
.web-link {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .web-link {
    margin-top: 20px;
  }
}
.web-link .button {
  display: block;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .web-link .button {
    display: inline-block;
  }
}

.post-content h1 + h1,
.post-content h1 + h2,
.post-content h1 + h3,
.post-content h1 + p,
.post-content h1 + ul,
.post-content h1 + ol,
.post-content h2 + h1,
.post-content h2 + h2,
.post-content h2 + h3,
.post-content h2 + p,
.post-content h2 + ul,
.post-content h2 + ol,
.post-content h3 + h1,
.post-content h3 + h2,
.post-content h3 + h3,
.post-content h3 + p,
.post-content h3 + ul,
.post-content h3 + ol {
  margin-top: 0.5em;
}
.post-content p + p,
.post-content p + ul,
.post-content p + ol,
.post-content ul + p,
.post-content ul + ul,
.post-content ul + ol,
.post-content ol + p,
.post-content ol + ul,
.post-content ol + ol {
  margin-top: 1.5em;
}
.post-content p + h1,
.post-content p + h2,
.post-content p + h3,
.post-content ul + h1,
.post-content ul + h2,
.post-content ul + h3,
.post-content ol + h1,
.post-content ol + h2,
.post-content ol + h3 {
  margin-top: 2em;
}
.post-content ul,
.post-content ol {
  margin-left: 2em;
}
.post-content li + li {
  margin-top: 0.25em;
}
.post-content img {
  display: block;
  margin: 1.5em 0;
  max-width: 100%;
  height: auto;
}
.post-content blockquote {
  margin: 3em;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .post-content blockquote {
    margin: 1.5em 1em;
  }
}
.post-content a:not(.button) {
  color: currentColor;
  text-decoration: underline;
}

.notfound {
  text-align: center;
}