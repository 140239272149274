//==============================
// Form
//==============================

@mixin input-reset {
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
}

select {
  &::-ms-expand {
    display: none;
  }
}

.contact-form {
  width: calc(100% - 80px);
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 100px;
}

form {
  width: 100% !important;

  @include bp(xs) {
    width: calc(100% - 40px);
  }

  div {
    margin-bottom: $input-gutter * 2;

    @include bp(s) {
      margin-bottom: $input-gutter;
    }

    &.third {
      width: calc((100% / 3) - #{$input-gutter * 3} / 2);

      @include bp(s) {
        width: 100%;
      }
    }

    &.half {
      width: calc(50% - #{$input-gutter});

      @include bp(s) {
        width: 100%;
      }
    }

    &.full {
      width: 100%;
    }
  }
}

label {

  &.wpforms-field-label {
    @include text;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;

    @include bp(m) {
      font-size: 15px !important;
    }

    @include bp(s) {
      font-size: 14px !important;
    }
  }
  

  &.wpforms-error {
    @include text;
    font-weight: 400 !important;
    color: #ff0000 !important;
  }
}

input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea {
  @include input-reset;
  width: 100%;
  margin: 0 0 10px !important;
  padding: 1.5em 1.2em !important;
  font: 400 normal 16px/1.5 $rubik !important;
  color: $white !important;
  transition: border-color .3s;
  border: #444 solid 2px !important;
  background: #444 !important;
  border-radius: 0 !important;

  @include bp(l) {
    font-size: 15px;
  }

  @include bp(m) {
    font-size: 14px;
  }

  &:focus {
    outline: 0;
    border-color: $brown !important;
  }
}

select {
  background-image: url('assets/images/global/select-arrow.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right center;
  padding-right: 3em;
}

button[type="submit"] {
  margin: 20px auto 0 !important;
  display: block !important;
  font: 400 normal 22px/1.3 $bebas !important;
  text-transform: uppercase !important;
  color: $white !important;
  letter-spacing: .05em !important;
  background-color: $brown !important;
  display: inline-block !important;
  padding: .5em 1.2em !important;
  text-decoration: none !important;
  transition: background-color .3s !important;
  border: 0 !important;
  border-radius: 2px !important;

  @include bp(m) {
    font-size: 20 !important;
  }

  @include bp(s) {
    font-size: 18px !important;
  }

  &:hover,
  &:focus {
    outline: 0 !important;
    cursor: pointer !important;
    background-color: darken($brown, 10%) !important;
  }
}

.wpforms-container {
  margin: 0 !important;
}

.wpforms-confirmation-container-full {
  background: none !important;
  border: 0 !important;
  text-align: center !important;

  p {
    @include text;
  }
}
