//==============================
// Variables
//==============================


// Colors
//------------------------------

$selection: #b3d4fc;

$white: #fff;
$black: #000;
$charcoal: #222;
$grey: #888;
$brown: #9e927e;

$bebas: 'Bebas Neue', Arial Narrow, Helvetica, Arial, sans-serif;
$rubik: 'Rubik', Helvetica, Arial, sans-serif;

$input-gutter: 10px;
