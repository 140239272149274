//==============================
// Typography
//==============================

h1, h2, h3, h4, h5, h6, p, ul, ol, li {
  margin: 0;
  padding: 0;
}

@mixin title1 {
  font: 400 normal 48px/1 $bebas;
  text-transform: uppercase;
  letter-spacing: .03em;
  color: $grey;

  @include bp(s) {
    font-size: 30px;
  }
}

.title1 {
  @include title1;

  + .text {
    margin-top: 1em;
  }
}

@mixin title2 {
  font: 400 normal 40px/1.2 $rubik;
  letter-spacing: -.01em;
  color: $brown;

  @include bp(m) {
    font-size: 33px;
  }

  @include bp(s) {
    font-size: 30px;
  }

  @include bp(xs) {
    font-size: 25px;
  }
}

.title2 {
  @include title2;

  + .text {
    margin-top: .5em;
  }
}

@mixin text {
  font: 300 normal 18px/1.6 $rubik;
  color: $grey;

  @include bp(m) {
    font-size: 16px;
  }

  @include bp(s) {
    font-size: 15px;
  }
}

.text {
  @include text;

  + .button {
    margin-top: 1em;
  }

  a {
    color: $brown;
    text-decoration: none;
    border-bottom: $brown solid 1px;
    transition: color .2s, border-bottom-color .2s;

    &:hover,
    &:focus {
      color: darken($brown, 10%);
      border-bottom-color: darken($brown, 10%);
    }
  }
}
